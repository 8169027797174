// src/components/Login.js
import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import '../styles/global.css'; // Only global.css
import Captcha from './captcha';
import { SITE_URL, API_URL } from './config';
import { UserContext } from '../context/UserContext';
import twitterAvatar from '../assets/images/img.png';
import TelegramButton from './TelegramButton'; // Import the component

const Login = () => {
  const [message, setMessage] = useState(null);
  const [captchaData, setCaptchaData] = useState(null);
  const navigate = useNavigate();
  const { user, token, setUser, setToken } = useContext(UserContext);

  // Add the 'page-login' class to the body
  useEffect(() => {
    document.body.classList.add('page-login');
    return () => {
      document.body.classList.remove('page-login');
    };
  }, []);

  // Redirect to dashboard if already logged in
  useEffect(() => {
    if (user && token) {
      navigate('/dashboard');
    }
  }, [user, token, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!captchaData || !captchaData.captcha_id || !captchaData.captcha_input) {
      setMessage({ type: 'error', text: 'Please complete the captcha.' });
      setTimeout(() => setMessage(null), 3000);
      return;
    }

    const formData = new FormData(e.target);
    const data = {
      username: formData.get('username'),
      password: formData.get('password'),
      captcha: captchaData.captcha_input,
      captcha_id: captchaData.captcha_id,
    };

    try {
      const response = await fetch(`${API_URL}/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include', // Include cookies if necessary
        body: JSON.stringify(data),
      });

      const result = await response.json();

      if (response.ok) {
        // Store token and user data in context
        setToken(result.access_token);
        setUser(result.user);

        // Display success message and redirect to dashboard
        setMessage({ type: 'success', text: 'Login successful! Redirecting...' });
        setTimeout(() => {
          setMessage(null);
          navigate('/dashboard');
        }, 2000);
      } else {
        // Display error message returned by the API
        setMessage({ type: 'error', text: result.msg || 'Login error.' });
        setTimeout(() => setMessage(null), 3000);
      }
    } catch (error) {
      console.error('Error during login:', error);
      setMessage({ type: 'error', text: 'Error connecting to the server.' });
      setTimeout(() => setMessage(null), 3000);
    }
  };

  const handleCaptchaData = (data) => {
    setCaptchaData(data);
  };

  return (
    <div className="container">
      <div className="telegram-button-container">
        <TelegramButton /> {/* Adds the Telegram button */}
      </div>
      <div className="content-wrapper">
        <div className="twitter-container">
          <div className="twitter">
            <button type="button" className="x-back" onClick={() => navigate(-1)}>
              ←
            </button>
            <div className="twitter-buttons">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`https://twitter.com/intent/tweet?text=caldodepollo:%20${encodeURIComponent(SITE_URL)}`}
              >
                <button className="x-post">Post</button>
              </a>
            </div>
          </div>
          <div className="twitter-inline">
            <div
              className="twitter-avatar"
              style={{
                backgroundImage: `url(${twitterAvatar})`, // Apply the imported image
              }}
            ></div>
            <div className="twitter-text">
              <h3>#ExclusiveContent #teen #lxli #caldodepollo</h3>
              <h3>
                <a
                  href={SITE_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="site-link"
                >
                  {SITE_URL}
                </a>
              </h3>
            </div>
          </div>
        </div>

        <div className="form-container">
          <h2>Welcome Back!</h2>
          <p>Log in to access the best and most exclusive teen content.</p>
          <form onSubmit={handleSubmit} id="loginForm">
            <input type="text" name="username" placeholder="Username" required />
            <input type="password" name="password" placeholder="Password" required />
            <Captcha onCaptchaData={handleCaptchaData} />
            <button type="submit" className="login-button">Login</button>
          </form>

          {message && (
            <div
              className={`message-container ${message.type === 'success' ? 'success-message' : 'error-message'}`}
            >
              {message.text}
            </div>
          )}

          <p>
            Don't have an account? <Link to="/register">Register here</Link>.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
