// src/components/AllUsers.js
import React, { useState, useEffect } from 'react';
import { API_URL } from './config';
import '../styles/allusers.css';

const AllUsers = () => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]); // For search functionality
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [stats, setStats] = useState({
    lastHour: 0,
    last24Hours: 0,
    lastWeek: 0,
    peakHour: 'N/A',
    peakHourCount: 0,
  });
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const usersPerPage = 50; // Updated to 50 users per page

  // State for detailed user info
  const [selectedUser, setSelectedUser] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [detailsLoading, setDetailsLoading] = useState(false);
  const [detailsError, setDetailsError] = useState(null);

  const fetchUsers = async () => {
    try {
      const response = await fetch(`${API_URL}/allusers`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });

      const result = await response.json();

      if (response.ok) {
        const sortedUsers = result.users.sort((a, b) => {
          const dateA = new Date(a.registered_at);
          const dateB = new Date(b.registered_at);
          const isValidA = !isNaN(dateA);
          const isValidB = !isNaN(dateB);

          if (isValidA && isValidB) {
            return dateB - dateA;
          } else if (isValidA) {
            return -1;
          } else if (isValidB) {
            return 1;
          } else {
            return 0;
          }
        });
        setUsers(sortedUsers);
        setFilteredUsers(sortedUsers);
        calculateStats(sortedUsers);
        setLoading(false);
      } else {
        setError(result.msg || 'Error fetching users.');
        setLoading(false);
      }
    } catch (err) {
      console.error('Error fetching users:', err);
      setError('Server connection error.');
      setLoading(false);
    }
  };

  const calculateStats = (users) => {
    const now = new Date();
    let lastHour = 0;
    let last24Hours = 0;
    let lastWeek = 0;
    const hourCounts = {};

    users.forEach((user) => {
      const registeredAt = new Date(user.registered_at);

      if (!isNaN(registeredAt)) {
        const timeDiff = now - registeredAt;

        // Count registrations in the last hour, 24 hours, and week
        if (timeDiff <= 60 * 60 * 1000) {
          lastHour += 1;
        }

        if (timeDiff <= 24 * 60 * 60 * 1000) {
          last24Hours += 1;
        }

        if (timeDiff <= 7 * 24 * 60 * 60 * 1000) {
          lastWeek += 1;
        }

        // Count registrations per hour of the day
        const hour = registeredAt.getHours();
        hourCounts[hour] = (hourCounts[hour] || 0) + 1;
      }
    });

    console.log("Hour Counts:", hourCounts); // Debugging log

    // Find the peak hour with the highest number of registrations
    let peakHour = 'N/A';
    let peakHourCount = 0;
    for (const [hour, count] of Object.entries(hourCounts)) {
      if (count > peakHourCount) {
        peakHourCount = count;
        peakHour = `${padZero(hour)}:00 - ${padZero(hour)}:59`;
      }
    }

    console.log("Calculated Stats:", {
      lastHour,
      last24Hours,
      lastWeek,
      peakHour,
      peakHourCount,
    });

    // Update statistics with calculated values
    setStats({
      lastHour,
      last24Hours,
      lastWeek,
      peakHour,
      peakHourCount,
    });
  };

  const padZero = (num) => {
    return num.toString().padStart(2, '0');
  };

  useEffect(() => {
    fetchUsers();
    const interval = setInterval(fetchUsers, 30000); // Refresh every 30 seconds
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const filtered = users.filter(user =>
      user.username.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredUsers(filtered);
    setCurrentPage(1); // Reset to first page on search
  }, [searchTerm, users]);

  // Fetch user details when a user is selected
  const fetchUserDetails = async (username) => {
    setDetailsLoading(true);
    setDetailsError(null);
    setUserDetails(null);
    try {
      const response = await fetch(`${API_URL}/userdetails/${username}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });

      const result = await response.json();

      if (response.ok) {
        setUserDetails(result.user);
        setDetailsLoading(false);
      } else {
        setDetailsError(result.msg || 'Error fetching user details.');
        setDetailsLoading(false);
      }
    } catch (err) {
      console.error('Error fetching user details:', err);
      setDetailsError('Server connection error.');
      setDetailsLoading(false);
    }
  };

  // Handle selecting a user to view details
  const handleUserClick = (user) => {
    setSelectedUser(user.username);
    fetchUserDetails(user.username);
  };

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  if (loading) {
    return <div className="loading-message">Loading users...</div>;
  }

  // Function to format date and time
  const formatDateTime = (dateString) => {
    const date = new Date(dateString);
    if (isNaN(date)) {
      return 'Invalid Date';
    }
    return (
      date.toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      }) +
      ', ' +
      date.toLocaleDateString([], {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      })
    );
  };

  // Pagination Logic
  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);
  const totalPages = Math.ceil(filteredUsers.length / usersPerPage);

  const goToPreviousPage = () => {
    setCurrentPage(prev => (prev > 1 ? prev - 1 : prev));
  };

  const goToNextPage = () => {
    setCurrentPage(prev => (prev < totalPages ? prev + 1 : prev));
  };

  return (
    <div className="all-users-container">
      <h2>All Users</h2>

      {/* Statistics Section */}
      <div className="stats-container">
        <div className="stat-card">
          <h3>Last Hour</h3>
          <p>{stats.lastHour} Registrations</p>
        </div>
        <div className="stat-card">
          <h3>Last 24 Hours</h3>
          <p>{stats.last24Hours} Registrations</p>
        </div>
        <div className="stat-card">
          <h3>Last Week</h3>
          <p>{stats.lastWeek} Registrations</p>
        </div>
        <div className="stat-card">
          <h3>Peak Hour</h3>
          <p>{stats.peakHour}</p>
          <p><strong>Registrations During Peak Hour:</strong> {stats.peakHourCount}</p>
        </div>
      </div>

      {/* Search Bar */}
      <div className="search-bar">
        <input
          type="text"
          placeholder="Search by username..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      {/* Users Grid */}
      <div className="users-grid">
        {currentUsers.map((user) => (
          <div
            className="user-card"
            key={user.id}
            onClick={() => handleUserClick(user)}
            style={{ cursor: 'pointer' }}
          >
            <h3>{user.username}</h3>
            <p><strong>Invitation Code:</strong> {user.invite_code}</p>
            <p><strong>Invites:</strong> {user.invites_count}</p>
            <p><strong>Tier:</strong> {user.tier}</p>
            <p><strong>Registered At:</strong> {formatDateTime(user.registered_at)}</p>
            <p><strong>Invited By:</strong> {user.invited_by || 'N/A'}</p>
            <p className="registration-ip"><strong>Registration IP:</strong> {user.registration_ip || 'N/A'}</p>
          </div>
        ))}
      </div>

      {/* Pagination Controls */}
      <div className="pagination">
        <button
          onClick={goToPreviousPage}
          disabled={currentPage === 1}
          className="pagination-button"
        >
          &#8592; Previous
        </button>
        <span className="page-info">
          Page {currentPage} of {totalPages}
        </span>
        <button
          onClick={goToNextPage}
          disabled={currentPage === totalPages}
          className="pagination-button"
        >
          Next &#8594;
        </button>
      </div>

      {/* Detailed User Info */}
      {selectedUser && (
        <div className="user-details-container">
          <h3>Details for: {selectedUser}</h3>
          {detailsLoading && <div className="loading-message">Loading details...</div>}
          {detailsError && <div className="error-message">{detailsError}</div>}
          {userDetails && (
            <div className="user-details">
              <p><strong>Total Invites:</strong> {userDetails.invites_count}</p>
              <p><strong>Total Invited Users:</strong> {userDetails.total_invited_users}</p>
              <p><strong>Invited Users with Same IP:</strong> {userDetails.same_ip_invited_users}</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default AllUsers;
