// src/components/AllUsersGet.js
import React, { useState, useEffect } from 'react';
import { API_URL } from './config';
import '../styles/allusersget.css';

const AllUsersGet = () => {
  const [visitors, setVisitors] = useState([]);
  const [filteredVisitors, setFilteredVisitors] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const visitorsPerPage = 50;

  const fetchVisitors = async () => {
    try {
      const response = await fetch(`${API_URL}/allvisitors`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });

      const result = await response.json();

      if (response.ok) {
        setVisitors(result.visitors);
        setFilteredVisitors(result.visitors);
        setLoading(false);
      } else {
        setError(result.msg || 'Error fetching visitors.');
        setLoading(false);
      }
    } catch (err) {
      console.error('Error fetching visitors:', err);
      setError('Server connection error.');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchVisitors();
    const interval = setInterval(fetchVisitors, 30000); // Refresh every 30 seconds
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const filtered = visitors.filter(visitor =>
      visitor.ip.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredVisitors(filtered);
    setCurrentPage(1); // Reset to first page on search
  }, [searchTerm, visitors]);

  // Pagination Logic
  const indexOfLastVisitor = currentPage * visitorsPerPage;
  const indexOfFirstVisitor = indexOfLastVisitor - visitorsPerPage;
  const currentVisitors = filteredVisitors.slice(indexOfFirstVisitor, indexOfLastVisitor);
  const totalPages = Math.ceil(filteredVisitors.length / visitorsPerPage);

  const goToPreviousPage = () => {
    setCurrentPage(prev => (prev > 1 ? prev - 1 : prev));
  };

  const goToNextPage = () => {
    setCurrentPage(prev => (prev < totalPages ? prev + 1 : prev));
  };

  // Function to format date and time
  const formatDateTime = (dateString) => {
    const date = new Date(dateString);
    if (isNaN(date)) {
      return 'Invalid Date';
    }
    return (
      date.toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      }) +
      ', ' +
      date.toLocaleDateString([], {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      })
    );
  };

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  if (loading) {
    return <div className="loading-message">Loading visitors...</div>;
  }

  return (
    <div className="all-visitors-container">
      <h2>All Visitors</h2>

      {/* Search Bar */}
      <div className="search-bar">
        <input
          type="text"
          placeholder="Search by IP..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      {/* Visitors Grid */}
      <div className="visitors-grid">
        {currentVisitors.map((visitor, index) => (
          <div className="visitor-card" key={index}>
            <h3>IP: {visitor.ip}</h3>
            <p><strong>Visit Count:</strong> {visitor.visit_count}</p>
            <p><strong>User Agents:</strong></p>
            <ul>
              {visitor.user_agents.map((ua, idx) => (
                <li key={idx}>{ua}</li>
              ))}
            </ul>
            <p><strong>First Visit:</strong> {formatDateTime(visitor.first_visit)}</p>
            <p><strong>Last Visit:</strong> {formatDateTime(visitor.last_visit)}</p>
            <p><strong>Country:</strong> {visitor.ip_info.country || 'N/A'}</p>
            <p><strong>Region:</strong> {visitor.ip_info.regionName || 'N/A'}</p>
            <p><strong>City:</strong> {visitor.ip_info.city || 'N/A'}</p>
            <p><strong>ISP:</strong> {visitor.ip_info.isp || 'N/A'}</p>
          </div>
        ))}
      </div>

      {/* Pagination Controls */}
      <div className="pagination">
        <button
          onClick={goToPreviousPage}
          disabled={currentPage === 1}
          className="pagination-button"
        >
          &#8592; Previous
        </button>
        <span className="page-info">
          Page {currentPage} of {totalPages}
        </span>
        <button
          onClick={goToNextPage}
          disabled={currentPage === totalPages}
          className="pagination-button"
        >
          Next &#8594;
        </button>
      </div>
    </div>
  );
};

export default AllUsersGet;
