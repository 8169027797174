// src/components/Dashboard.js
import React, { useState, useRef, useContext, useEffect, useMemo } from 'react';
import '../styles/dashboard.css';
import { UserContext } from '../context/UserContext';
import { SITE_URL, API_URL } from './config';
import { tiers } from './tiersData';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaExternalLinkAlt, FaVideo, FaUserPlus, FaPlay } from 'react-icons/fa'; // Importando FaPlay para Free Preview

// Subcomponentes

const LoadingSpinner = () => (
  <div className="loading-spinner">
    <div className="spinner"></div>
  </div>
);

const ProfileData = ({ user }) => (
  <div className="profileData">
    <div className="profile-grid">
      <div><strong>Username:</strong> {user.username}</div>
      <div><strong>ID:</strong> {user.id}</div>
      <div><strong>Tier:</strong> {user.currentTier ? user.currentTier.name : 'Locked'}</div>
      <div><strong>Invites:</strong> {user.invites_count}</div>
    </div>
  </div>
);

const TierInfo = ({ invitesNeeded, nextTier, user }) => (
  <div className="card tier-info">
    {invitesNeeded > 0 && nextTier ? (
      <h3>
        You have <span className="color">{user.invites_count}</span> invites. You need{' '}
        <span className="color">{invitesNeeded}</span> more invites to reach{' '}
        <span className="color">{nextTier.name}</span>.
      </h3>
    ) : (
      <h3>
        Congratulations, <span className="color">{user.username}</span>! You have unlocked the
        highest tier available.
      </h3>
    )}
  </div>
);

const InviteLink = ({ inviteLink }) => {
  const inviteLinkRef = useRef(null);

  const copyInviteLink = () => {
    if (inviteLinkRef.current) {
      inviteLinkRef.current.select();
      inviteLinkRef.current.setSelectionRange(0, 99999); // Para dispositivos móveis

      navigator.clipboard
        .writeText(inviteLinkRef.current.value)
        .then(() => {
          toast.success('Link copied to clipboard!');
        })
        .catch((err) => {
          console.error('Failed to copy text ', err);
          toast.error('Failed to copy the link. Please copy manually.');
        });
    }
  };

  return (
    <div className="card invite-link">
      <h3>Your Invite Link</h3>
      <div className="invite-link-container">
        <input
          type="text"
          readOnly
          id="inviteLink"
          value={inviteLink}
          ref={inviteLinkRef}
          aria-label="Invite Link"
        />
        <button className="copyButton" onClick={copyInviteLink} aria-label="Copy Invite Link">
          Copy Link
        </button>
      </div>
      <h4 className="share-text">
        Share your invite link with your friends, or{' '}
        <span className="green">spread your invitation</span> on social media platforms like{' '}
        <span className="color">Twitter</span>, <span className="color">Reddit</span>,{' '}
        <span className="color">Snapchat</span>, <span className="color">Instagram</span>,{' '}
        <span className="color">Facebook</span>, and others using relevant keywords.
      </h4>
    </div>
  );
};

const ActionButtons = ({ username }) => (
  <div className="card action-buttons">
    <div className="action-header">
      <h3>Hello <span className="color">{username}</span></h3>
    </div>
    <div className="action-content">
      <div className="action-item">
        <p>Always check our status site to see which site we are on.</p>
        <a href={SITE_URL} target="_blank" rel="noopener noreferrer" className="action-button-link">
          <button className="action-button" aria-label="Enter Website">
            <FaExternalLinkAlt className="button-icon" /> Enter Website
          </button>
        </a>
      </div>
      <div className="action-item">
        <p>Explore our free preview to get a better understanding:</p>
        <Link to="/preview" className="action-button-link">
          <button className="action-button" aria-label="Free Preview">
            <FaPlay className="button-icon" /> Free Preview
          </button>
        </Link>
      </div>
      <div className="action-item">
        <p>If you don't know how to view more content:</p>
        <Link to="/get" className="action-button-link">
          <button className="action-button" aria-label="Get More Videos">
            <FaVideo className="button-icon" /> Get More Videos
          </button>
        </Link>
      </div>
      <div className="action-item">
        <p>If you want to check your invite:</p>
        <Link to="/getinvites" className="action-button-link">
          <button className="action-button" aria-label="Get Invite">
            <FaUserPlus className="button-icon" /> Get Invite
          </button>
        </Link>
      </div>
    </div>
  </div>
);

// Componente Principal

const Dashboard = () => {
  const { user, token, setUser } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Função para buscar os dados do perfil do usuário
  const fetchProfile = async () => {
    try {
      const response = await fetch(`${API_URL}/profile`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        credentials: 'include',
      });

      const result = await response.json();

      if (response.ok) {
        setUser({
          ...result,
          currentTier: getCurrentTier(result.invites_count),
        });
      } else {
        setError(result.msg || 'Failed to fetch profile.');
        toast.error(result.msg || 'Failed to fetch profile.');
      }
    } catch (error) {
      console.error('Error fetching profile:', error);
      setError('An unexpected error occurred.');
      toast.error('An unexpected error occurred.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (token) {
      fetchProfile();
    } else {
      setLoading(false);
    }
  }, [token]);

  // Função para determinar o Tier atual com base nos convites usando tiersData.js
  const getCurrentTier = (invites) => {
    const sortedTiers = tiers
      .slice()
      .sort((a, b) => b.inviteThreshold - a.inviteThreshold);
    return sortedTiers.find((tier) => invites >= tier.inviteThreshold) || null;
  };

  // Função para determinar os convites necessários para o próximo Tier
  const getInvitesNeededForNextTier = (invites) => {
    const sortedTiers = tiers.slice().sort((a, b) => a.inviteThreshold - b.inviteThreshold);
    const nextTier = sortedTiers.find((tier) => invites < tier.inviteThreshold);
    if (nextTier) {
      return { invitesNeeded: nextTier.inviteThreshold - invites, nextTier };
    }
    return { invitesNeeded: 0, nextTier: null };
  };

  // Memoização dos cálculos de Tier para evitar recalculações desnecessárias
  const { currentTier, invitesNeeded, nextTier } = useMemo(() => {
    if (user) {
      const tier = getCurrentTier(user.invites_count);
      const { invitesNeeded, nextTier } = getInvitesNeededForNextTier(user.invites_count);
      return { currentTier: tier, invitesNeeded, nextTier };
    }
    return { currentTier: null, invitesNeeded: 0, nextTier: null };
  }, [user]);

  if (loading) {
    return <LoadingSpinner />;
  }

  if (!user) {
    return (
      <div className="dashboard-content">
        <h2>You are not authenticated. Please, <Link to="/">do login</Link>.</h2>
      </div>
    );
  }

  return (
    <div className="dashboard-container">
      <ToastContainer position="top-right" autoClose={3000} hideProgressBar />
      <ProfileData user={{ ...user, currentTier }} />
      <TierInfo invitesNeeded={invitesNeeded} nextTier={nextTier} user={user} />
      <ActionButtons username={user.username} />
      <InviteLink inviteLink={`${SITE_URL}/register?invite=${user.invite_code}`} />
    </div>
  );
};

export default Dashboard;
