// src/components/Preview.js
import React, { useState, useContext } from 'react';
import '../styles/dashboard.css';
import { UserContext } from '../context/UserContext';

const Preview = () => {
  const { user } = useContext(UserContext);

  // Lista de vídeos com suas URLs e rótulos
  const videos = [
    {
      src: 'https://res.cloudinary.com/dyaw5fbyp/video/upload/v1731126196/c6ijy838hhsuas5t8ayj.mp4',
      label: 'PREVIEW 1',
    },
    {
      src: 'https://res.cloudinary.com/dyaw5fbyp/video/upload/v1731126190/vyabgg29p0wnvb7dofo2.mp4',
      label: 'PREVIEW 2',
    },
    {
      src: 'https://res.cloudinary.com/dyaw5fbyp/video/upload/v1731126190/v4egwqflqcqpzfpvdrga.mp4',
      label: 'PREVIEW 3',
    },
    {
      src: 'https://res.cloudinary.com/dyaw5fbyp/video/upload/v1731126190/hxhxoalah5havppdk2hm.mp4',
      label: 'PREVIEW 4',
    },
  ];

  // Estado para controlar quais vídeos foram revelados
  const [revealedVideos, setRevealedVideos] = useState(
    videos.map(() => false) // Inicialmente, nenhum vídeo está revelado
  );

  // Função para revelar um vídeo específico
  const handleReveal = (index) => {
    setRevealedVideos((prevState) => {
      const newState = [...prevState];
      newState[index] = true;
      return newState;
    });
  };

  return (
    <div className="preview-page">
      <div className="card">
        <h3 className="centermid">
          Hello, <span>{user.username}</span>! <br />
        </h3>
        <h3 className="centermid" style={{ marginTop: '5px' }}>
          Here's a free preview of our videos!
        </h3>
      </div>

      <div className="preview-container">
        {videos.map((video, index) => (
          <div className="video-card" key={index}>
            <div className="video-wrapper">
              <video
                controls
                className={!revealedVideos[index] ? 'blurred-video' : ''}
              >
                <source src={video.src} type="video/mp4" />
                Your browser does not support the video element.
              </video>
              {!revealedVideos[index] && (
                <div
                  className="spoiler-overlay"
                  onClick={() => handleReveal(index)}
                  role="button"
                  tabIndex={0}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      handleReveal(index);
                    }
                  }}
                  aria-label="Click to reveal the video"
                >
                  <span className="spoiler-text">🔒 Spoiler</span>
                </div>
              )}
            </div>
            <p>{video.label}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Preview;
