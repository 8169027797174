// src/components/Captcha.js
import React, { useEffect, useState } from 'react';
import { API_URL } from './config'; // Importa o API_URL do config.js

const Captcha = ({ onCaptchaData }) => {
    const [num1, setNum1] = useState(null);
    const [num2, setNum2] = useState(null);
    const [captchaId, setCaptchaId] = useState(null);
    const [loading, setLoading] = useState(true);
    const [userInput, setUserInput] = useState('');

    useEffect(() => {
        fetchCaptcha();
    }, []);

    const fetchCaptcha = () => {
        fetch(`${API_URL}/captcha`, {
            method: 'GET',
            credentials: 'include', // Inclui cookies, se necessário
        })
            .then(response => response.json())
            .then(data => {
                setNum1(data.num1);
                setNum2(data.num2);
                setCaptchaId(data.captcha_id);
                setLoading(false);
                // Notifica o componente pai com o captcha_id e input vazio
                onCaptchaData({
                    captcha_id: data.captcha_id,
                    captcha_input: '',
                });
            })
            .catch(error => {
                console.error('Erro ao buscar captcha:', error);
                setLoading(false);
            });
    };

    const refreshCaptcha = () => {
        setLoading(true);
        fetchCaptcha();
    };

    const handleChange = (e) => {
        const value = e.target.value;
        setUserInput(value);
        // Notifica o componente pai sempre que o input muda
        onCaptchaData({
            captcha_id: captchaId,
            captcha_input: value,
        });
    };

    return (
        <div className="captcha-container">
            {loading ? (
                <p>Loading captcha...</p>
            ) : (
                <>
                    <div className="captcha-top">
                        <div className="captcha-question">
                            {num1} + {num2} = ?
                        </div>
                        <button
                            type="button"
                            className="reroll-button"
                            onClick={refreshCaptcha}
                            title="Reload Captcha"
                        >
                            😈
                        </button>
                    </div>
                    <input
                        type="text"
                        name="captcha"
                        placeholder="Enter the answer"
                        onChange={handleChange}
                        required
                    />
                </>
            )}
        </div>
    );
};

export default Captcha;
