// src/components/Get.js
import React, { useContext } from 'react';
import '../styles/dashboard.css';
import { UserContext } from '../context/UserContext';
import { Link } from 'react-router-dom';
import { SITE_URL, TELEGRAM_LINK } from './config';
import { tiers } from './tiersData'; // Importando os dados dos Tiers

const Get = () => {
  const { user } = useContext(UserContext);

  // Função para determinar o Tier do usuário com base no número de convites usando tiersData.js
  const getTier = (invitesCount) => {
    // Ordena os tiers em ordem decrescente de inviteThreshold
    const sortedTiers = [...tiers].sort((a, b) => b.inviteThreshold - a.inviteThreshold);
    for (let tier of sortedTiers) {
      if (invitesCount >= tier.inviteThreshold) {
        return tier.id;
      }
    }
    return 0; // Se não se qualificar para nenhum Tier
  };

  const userTier = getTier(user.invites_count);

  return (
    <div className="tier-container">
      {/* Saudação Inicial */}
      <div className="card">
        <h3 className="centermid">
          Hello, <span>{user.username}</span>! <br />
        </h3>
        <h3 className="centermid" style={{ marginTop: '5px' }}>
          You can get more videos by{' '}
          <span className="color">buying instant access</span> or{' '}
          <span className="color">inviting others</span>.
        </h3>
      </div>

      {/* Mapeamento dos Tiers */}
      {tiers.map((tier) => (
        <div key={tier.id} className="tierCard">
          <h3>
            <span className={tier.className}>{tier.name}</span>
            <br />
            {tier.price} or {tier.invites} invites
            <br />
            <br />
            <span className={tier.className}>
              {tier.description.split('\n').map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
              ))}
            </span>
          </h3>
          <div className="tier-buttons">
            <Link to={`/tier/${tier.id}`}>
              <button className="tier">{tier.name}</button>
            </Link>
            <a href={TELEGRAM_LINK} target="_blank" rel="noopener noreferrer">
              <button className="access">Instant Access</button>
            </a>
            <Link to="/getinvites">
              <button className="invite">Invite Now</button>
            </Link>
          </div>
        </div>
      ))}

      {/* Opcional: Adicione mais conteúdo ou componentes aqui */}
    </div>
  );
};

export default Get;
